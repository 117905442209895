<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
	components: { LazyHydrate },
	props: {
		small: Boolean,
		smallCarousel: Boolean,
		square: Boolean,
		featured: Boolean,
		type: String,
	},

	data() {
		return {
			cardType: this.type || this.$srv('productCardType.name', 'Design6'),
		}
	},
	computed: {
		btnType() {
			if (this.$b.d) return this.$srv('productCardType.btnDesktop')
			return this.$srv('productCardType.btnMobile')
		},
		hasAttrsCnf() {
			return (
				this.$srv('productCardTags', []).length &&
				['design1', 'design4', 'design6'].includes(this.cardType.toLowerCase())
			)
		},
		compName() {
			if (this.small) {
				return `${this.cardType}Small`
			} else if (this.smallCarousel) {
				return `ForSmallCarousel`
			} else if (this.square) {
				return `Sq`
			} else if (this.featured) {
				return `${this.cardType}Featured`
			} else {
				return this.cardType
			}
		},
		sqOrder() {
			if (this.cardType == 'Design1' || this.cardType == 'Design3')
				return {
					name: 1,
					price: 2,
					brand: 3,
				}
			else if (this.cardType == 'Design2')
				return {
					name: 1,
					brand: 2,
					price: 3,
				}
			else if (this.cardType == 'Design4' || this.cardType == 'Design8')
				return {
					brand: 1,
					name: 2,
					price: 3,
				}
			else if (this.cardType == 'Design5' || this.cardType == 'Design6')
				return {
					price: 1,
					name: 2,
					brand: 3,
				}
			else
				return {
					price: 1,
					brand: 2,
					name: 3,
				}
		},
	},
}
</script>

<template>
	<LazyHydrate when-visible>
		<component
			:class="{ 'product-card--top-attrs': hasAttrsCnf }"
			:is="`ProductCard-${compName}`"
			v-bind="$attrs"
			v-on="$listeners"
			:type="type"
			:featured="featured"
			:small="small"
			:elementOrder="sqOrder"
		/>
	</LazyHydrate>
</template>

<style lang="scss" scoped>
::v-deep .product-card {
	z-index: 1;
	transition: transform 0.2s ease-out;

	&:hover {
		transform: scale(1.02);
	}
}

::v-deep a.card-anchor:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 1;
	left: 0;
}

::v-deep div:has(.qty-btn__container) {
	z-index: 2;
}

.product-card--align-center {
	::v-deep .product-card__tags {
		display: flex;
		justify-content: center;
	}
}

.product-card--horizontal {
	&.product-card--align-center {
		& ::v-deep {
			.product-card__tags {
				flex-direction: column;
				align-items: center;
			}
			.product-card__rating-wrapper div {
				justify-content: center;
			}
			@for $i from 1 through 8 {
				.product-card-design#{$i}-small-horizontal {
					&__name,
					&__price,
					&__brand-wrapper {
						text-align: center;
					}
					&__prev-price {
						justify-content: center;
					}
					&__price-container {
						justify-content: center;
						.align-center {
							justify-content: center;
						}
					}
				}
			}
		}
	}
	&.product-card--align-right {
		& ::v-deep {
			.product-card__tags {
				flex-direction: column;
				align-items: flex-end;
			}
			.product-card__rating-wrapper div {
				justify-content: flex-end;
			}
			@for $i from 1 through 8 {
				.product-card-design#{$i}-small-horizontal {
					&__name,
					&__price,
					&__brand-wrapper {
						text-align: end;
					}
					&__prev-price {
						justify-content: flex-end;
					}
					&__price-container {
						justify-content: flex-end;
						.align-end {
							justify-content: flex-end;
						}
					}
				}
			}
		}
	}
}

.product-card--align-right {
	::v-deep {
		.product-card__tags {
			display: flex;
			justify-content: flex-end;
		}
	}
}
</style>

