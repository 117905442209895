<script>
export default {
	inject: ['manager'],
}
</script>

<template>
	<div
		v-if="manager.financingTagHtml && manager.financingTagHtml.length"
		class="product-card__financing-wrapper d-flex align-center w100"
		:class="{
			'justify-center': manager.alignment == 'center',
			'justify-end': manager.alignment == 'right',
		}"
	>
		<div
			v-for="(html, n) in manager.financingTagHtml"
			:key="n"
			:class="[
				'product-card__financing font-0 line-clamp-1 px-1 primary rounded',
				{
					'mb-1': !manager.hasTextTag && manager.discountPct,
				},
			]"
			v-html="html"
		></div>
	</div>
</template>

