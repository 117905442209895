<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'
import hooksMixin from '@/v-shop/mixins/hooks-mixin'

export default {
	lang: 'shop',
	mixins: [productCardMixin, hooksMixin('ProductCard')],
	props: {
		position: String,
	},
	cssVars: {
		selector: '.product-card',
	},
}
</script>

<template>
	<!--  VERTICAL CARD -->
	<div
		v-if="verticalCard"
		:class="[
			'pb-2 product-card--featured product-card-design5-with-timer-vertical-wrapper',
			{
				h100: !timerProduct,
				'product-card-design5-with-timer-vertical-wrapper-forBtn': hasHoverAction,
				'product-card--align-right': alignment == 'right',
				'product-card--align-center': alignment == 'center',
			},
		]"
		v-click-outside="!$b.d && onLinkBlurMobile"
	>
		<v-hover v-model="hover">
			<v-card
				:hover="!outOfStock"
				flat
				@click="cardClick"
				:ripple="false"
				:class="[
					$bem('product-card-design5-with-timer-vertical'),
					{
						'product-card-design5-with-timer-vertical__positionRight': position == 'right',
						'product-card-design5-with-timer-vertical__positionLeft': position == 'left',
						'card-border': cardBorder,
						h100: timerProduct && limitedTimeOffer,
					},
				]"
				class="d-flex flex-column base rounded-md overflow-hidden product-card"
			>
				<div :class="[$bem('__image-cont'), { 'no-stock': outOfStock }]">
					<hook zone="image">
						<Media :class="$bem('__main-image')" :src="mainImage" width="100%" aspect-ratio="1" />
						<div v-if="secondImage && hover && imgHover" :class="$bem('__second-image-cont')">
							<Media
								:src="secondImage"
								width="100%"
								:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '']"
							/>
						</div>
					</hook>

					<hook zone="event-icon">
						<CollectionEventIcon :product="product" :class="$bem('__event-icon')" />
					</hook>
				</div>

				<div
					:class="[
						'd-flex flex-column mx-3',
						$bem('__data-cont'),
						{ 'no-stock': outOfStock, 'pb-4': !timerProduct, 'h100 justify-space-between': hasAction },
					]"
					style="gap: 8px"
				>
					<ProductCardPrice
						:product="product"
						:currency="currency"
						:priceAmount="price"
						:prevPriceAmount="prevPrice"
						:discountPct="discountPct"
						:containerClass="[
							'd-flex align-center line-clamp-1 px-1 mt-3',
							{ 'justify-center': alignment == 'center', 'justify-end': alignment == 'right' },
						]"
						:prevPriceClass="[
							$bem('__prev-price'),
							'grey--text mr-2',
							timerProduct ? 'font-1' : 'font-2',
						]"
						:priceClass="[$bem('__price'), timerProduct ? 'font-6' : 'font-7', 'price line-clamp-1']"
						:discountClass="[$bem('__discount'), 'discount pa-2']"
						:elementOrder="{ prevPrice: 1, price: 2 }"
					/>

					<hook zone="attrs&financingTags">
						<ProductCard-FinancingTag :class="timerProduct ? 'font-0' : 'font-1'" />
					</hook>

					<hook zone="textTags">
						<ProductCard-TextTag :class="timerProduct ? 'font-0' : 'font-1'" />
					</hook>

					<ProductCard-Tags :class="timerProduct ? 'font-0' : 'font-1'" />

					<router-link :to="productRoute" custom v-slot="{ navigate, href }">
						<a
							class="card-anchor header"
							@click="!$b.d ? onLinkClickMobile(navigate, $event) : navigate($event)"
							:href="href"
						>
							<hook zone="name">
								<ProductCardText
									:textClass="[
										$bem('__name'),
										timerProduct ? 'font-2' : 'font-3',
										'line-clamp-2 px-1 header',
										alignment == 'left'
											? 'text-start'
											: alignment == 'center'
											? 'text-center'
											: 'text-end',
									]"
									:text="product.name"
								/>
							</hook>
						</a>
					</router-link>

					<ProductCard-VariantSelector />

					<hook zone="brand">
						<ProductCardText
							:textClass="[
								$bem('__brand'),
								'line-clamp-1 pa-1',
								alignment == 'left'
									? 'text-start'
									: alignment == 'center'
									? 'text-center'
									: 'text-end',
								timerProduct ? 'font-0' : 'font-1',
							]"
							:conditional="brand"
							:text="brand"
						/>
					</hook>

					<ProductCardRating :product="product" />

					<ProductCard-AttrsTags />

					<ProductCard-Actions />

					<hook zone="offerTimerProduct" v-if="timerProduct">
						<div :class="[$bem('__offerTimerProduct'), 'base pt-1 mt-3']" v-if="limitedTimeOffer">
							<LimitedTimeOfferCountdown
								:limitedTimeOffer="limitedTimeOffer"
								timerCard
								:class="{ 'mt-3': !hasAction }"
							/>
							<div class="mt-2 pb-2 text-center">
								<p
									:class="[
										$bem('__timer-date'),
										'text-uppercase line-clamp-3 mb-0 py-1 px-2 rounded font-0',
									]"
								>
									{{ limitedTimeOfferDate }}
								</p>
							</div>
						</div>
					</hook>

					<hook zone="wishlist-toggler" :class="{ 'no-stock': outOfStock }">
						<ProductWishlistToggler
							:productId="product.id"
							:class="$bem('__wishlist-toggler')"
							style="z-index: 1"
						/>
					</hook>
				</div>
			</v-card>
		</v-hover>
	</div>
	<!-- END OF VERTICAL CARD -->

	<!-- HORIZONTAL CARD FOR MOBILE -->
	<div
		v-else
		:class="{
			'product-card-design5-with-timer-horizontal-wrapper-forBtn': hasHoverAction && hover,
		}"
		v-click-outside="onLinkBlurMobile"
	>
		<v-card
			flat
			@click="cardClick"
			:ripple="false"
			:class="[
				$bem('product-card-design5-with-timer-horizontal'),
				{
					'product-card-design5-with-timer-horizontal__noTimer': !timerProduct && $b.t,
					'card-border': cardBorder,
				},
			]"
			class="d-flex overflow-hidden base product-card pt-2 flex-column"
		>
			<div class="d-flex overflow-hidden py-2">
				<div
					:class="[
						$bem('__image-cont', 'col col-5 pa-0 d-flex flex-column justify-center'),
						{ 'no-stock': outOfStock },
					]"
				>
					<hook zone="imageHorizontal">
						<Media
							:class="$bem('__main-image', 'px-1')"
							:src="mainImage"
							:width="$b.m ? '100%' : '200px'"
							:maxHeight="$b.t ? '200px' : ''"
							imgClass="product-card-design5-with-timer-horizontal__image--smallWidth"
						/>
					</hook>

					<hook zone="event-iconHorizontal">
						<CollectionEventIcon :product="product" :class="$bem('__event-icon')" />
					</hook>
				</div>

				<div
					class="col col-7 py-0 d-flex flex-column justify-space-between text-start"
					:class="[
						$bem('__data-cont'),
						{ 'no-stock': outOfStock, 'my-5': !hasAction && !timerProduct },
						$b.t ? 'pl-3' : 'pl-1',
					]"
					style="gap: 6px"
				>
					<ProductCardPrice
						:product="product"
						:currency="currency"
						:priceAmount="price"
						:prevPriceAmount="prevPrice"
						:discountPct="discountPct"
						containerClass="d-flex align-center line-clamp-1 mt-3"
						:prevPriceClass="['grey--text mr-2', $bem('__prev-price'), $b.m ? 'font-1' : 'font-2']"
						:priceClass="[$bem('__price'), $b.m ? 'font-5' : 'font-6', 'price line-clamp-1']"
						:discountClass="[$bem('__discount'), 'discount pa-2']"
						horizontalCard
						:elementOrder="{ prevPrice: 1, price: 2 }"
					/>

					<hook zone="attrs&financingTagsHorizontal">
						<ProductCard-FinancingTag />
					</hook>

					<hook zone="textTagsHorizontal">
						<ProductCard-TextTag />
					</hook>

					<ProductCard-Tags />

					<router-link :to="productRoute" custom v-slot="{ navigate, href }">
						<a class="card-anchor header" @click="onLinkClickMobile(navigate, $event)" :href="href">
							<hook zone="nameHorizontal">
								<ProductCardText
									:textClass="[$bem('__name'), 'line-clamp-2 header', $b.m ? 'font-3' : 'font-4']"
									:text="product.name"
								/>
							</hook>
						</a>
					</router-link>

					<ProductCard-VariantSelector />

					<hook zone="brand">
						<ProductCardText
							:textClass="[$bem('__brand'), 'line-clamp-1', timerProduct ? 'font-0' : 'font-1']"
							:conditional="brand"
							:text="brand"
						/>
					</hook>
				</div>
			</div>
			<ProductCardRating :product="product" />

			<ProductCard-Actions />

			<hook zone="offerHorizontalTimerProduct" :class="{ 'no-stock': outOfStock }" v-if="timerProduct">
				<div
					:class="[$bem('__offerTimerProduct'), 'base pt-1', { 'mt-4': $b.t }]"
					v-if="limitedTimeOffer"
				>
					<LimitedTimeOfferCountdown :limitedTimeOffer="limitedTimeOffer" timerCard class="mt-2" />
					<div class="mt-2 pb-2 text-center">
						<p
							class="text-uppercase line-clamp-3 mb-0 pt-1 pb-2 px-2 rounded font-0"
							:class="$bem('__timer-date')"
						>
							{{ limitedTimeOfferDate }}
						</p>
					</div>
				</div>
			</hook>

			<hook zone="wishlist-togglerHorizontal">
				<ProductWishlistToggler
					:productId="product.id"
					:class="[$bem('__wishlist-toggler'), { 'no-stock': outOfStock }]"
					style="z-index: 1"
					:height="30"
					:width="30"
				/>
			</hook>
		</v-card>
	</div>
	<!-- END OF HORIZONTAL CARD FOR MOBILE -->
</template>

<style lang="scss" scoped>
.card-border {
	border: 1px solid #b2b2b2;
}

.no-stock {
	opacity: 0.4;
}

// VERTICAL CARD CLASSES
.product-card-design5-with-timer-vertical {
	position: relative;
	cursor: pointer;
	justify-content: space-between;

	&__image-cont {
		position: relative;
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__event-icon {
		position: absolute;
		bottom: 0px;
		left: 8px;
		z-index: 1;
		width: 40px;
	}

	&__offerTimerProduct {
		border-top: 2px solid var(--offertext);
	}

	&__name,
	&__brand {
		display: -webkit-box;
	}

	&__wishlist-toggler {
		position: absolute;
		top: 6px;
		right: 6px;
		z-index: 1;
	}

	&__positionRight {
		border-top-right-radius: 30px;
		border-bottom-right-radius: 30px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	&__positionLeft {
		border-top-left-radius: 30px;
		border-bottom-left-radius: 30px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
}

::v-deep .product-card-design5-with-timer-vertical {
	.alignment-center {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}

	.alignment-right {
		float: right;
	}

	&__prev-price {
		column-gap: 8px;
	}

	&__discount {
		font-size: 1.1rem;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		border-bottom-right-radius: 4px;
	}
}

// HORIZONTAL CARD CLASSES
.product-card-design5-with-timer-horizontal {
	&__image-cont {
		position: relative;
	}

	&__main-image {
		left: 50%;
		transform: translateX(-50%);
	}

	&__offerTimerProduct {
		border-top: 2px solid var(--offertext);
	}

	&__name,
	&__timer-date,
	&__brand {
		display: -webkit-box;
	}

	&__event-icon {
		position: absolute;
		bottom: 0;
		left: 6px;
		z-index: 1;
	}

	&__wishlist-toggler {
		position: absolute;
		top: 7px;
		right: 6px;
		z-index: 1;
	}
}

::v-deep .product-card-design5-with-timer-horizontal {
	&__discount {
		font-size: 1.1rem;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		border-bottom-right-radius: 4px;
	}

	&__price,
	&__prev-price {
		display: -webkit-box;
	}
}
</style>

