var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.manager.financingTagHtml && _vm.manager.financingTagHtml.length)?_c('div',{staticClass:"product-card__financing-wrapper d-flex align-center w100",class:{
		'justify-center': _vm.manager.alignment == 'center',
		'justify-end': _vm.manager.alignment == 'right',
	}},_vm._l((_vm.manager.financingTagHtml),function(html,n){return _c('div',{key:n,class:[
			'product-card__financing font-0 line-clamp-1 px-1 primary rounded',
			{
				'mb-1': !_vm.manager.hasTextTag && _vm.manager.discountPct,
			} ],domProps:{"innerHTML":_vm._s(html)}})}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }