var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.verticalCard)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(!_vm.$b.d && _vm.onLinkBlurMobile),expression:"!$b.d && onLinkBlurMobile"}],class:[
		'pb-2 product-card-design8-with-timer-vertical-wrapper',
		{
			h100: !_vm.timerProduct,
			'product-card-design8-with-timer-vertical-wrapper-forBtn': _vm.hasHoverAction,
			'product-card--align-right': _vm.alignment == 'right',
			'product-card--align-center': _vm.alignment == 'center',
		} ]},[_c('v-hover',{model:{value:(_vm.hover),callback:function ($$v) {_vm.hover=$$v},expression:"hover"}},[_c('v-card',{class:[
				_vm.$bem('product-card-design8-with-timer-vertical'),
				'd-flex flex-column base rounded-md overflow-hidden product-card',
				{
					'card-border': _vm.cardBorder,
					h100: _vm.timerProduct && _vm.limitedTimeOffer,
				} ],attrs:{"hover":!_vm.outOfStock,"flat":"","ripple":false},on:{"click":_vm.cardClick}},[_c('div',{class:[_vm.$bem('__image-cont'), { 'no-stock': _vm.outOfStock }]},[_c('hook',{attrs:{"zone":"image"}},[_c('Media',{class:_vm.$bem('__main-image'),attrs:{"src":_vm.mainImage,"width":"100%","aspect-ratio":"1"}}),(_vm.secondImage && _vm.hover && _vm.imgHover)?_c('div',{class:_vm.$bem('__second-image-cont')},[_c('Media',{class:[_vm.$bem('__second-image'), _vm.hover ? _vm.$bem('__second-image--show') : ''],attrs:{"src":_vm.secondImage,"width":"100%"}})],1):_vm._e()],1),_c('hook',{attrs:{"zone":"event-icon"}},[_c('CollectionEventIcon',{class:_vm.$bem('__event-icon'),attrs:{"product":_vm.product}})],1)],1),_c('div',{class:[
					_vm.$bem('__data-cont'),
					'd-flex flex-column mx-3',
					{ 'no-stock': _vm.outOfStock, 'pb-4': !_vm.timerProduct, 'h100 justify-space-between': _vm.hasAction } ],staticStyle:{"gap":"8px"}},[_c('hook',{attrs:{"zone":"brand"}},[_c('ProductCardText',{attrs:{"textClass":[
							_vm.$bem('__brand'),
							'line-clamp-1 px-1 mt-3 ',
							_vm.alignment == 'left'
								? 'text-start'
								: _vm.alignment == 'center'
								? 'text-center'
								: 'text-end',
							_vm.timerProduct ? 'font-0' : 'font-1' ],"conditional":_vm.brand,"text":_vm.brand}})],1),_c('router-link',{attrs:{"to":_vm.productRoute,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
						var navigate = ref.navigate;
						var href = ref.href;
return [_c('a',{staticClass:"card-anchor header",attrs:{"href":href},on:{"click":function($event){!_vm.$b.d ? _vm.onLinkClickMobile(navigate, $event) : navigate($event)}}},[_c('hook',{attrs:{"zone":"name"}},[_c('ProductCardText',{attrs:{"textClass":[
									_vm.$bem('__name'),
									_vm.timerProduct ? 'font-2' : 'font-3',
									'line-clamp-2 px-1 header',
									_vm.alignment == 'left'
										? 'text-start'
										: _vm.alignment == 'center'
										? 'text-center'
										: 'text-end',
									{ 'mt-3': !_vm.brand } ],"text":_vm.product.name}})],1)],1)]}}],null,false,889776202)}),_c('ProductCard-VariantSelector'),_c('ProductCardPrice',{attrs:{"product":_vm.product,"currency":_vm.currency,"priceAmount":_vm.price,"prevPriceAmount":_vm.prevPrice,"discountPct":_vm.discountPct,"containerClass":[
						'd-flex align-center line-clamp-1 px-1',
						{
							'justify-center': _vm.alignment == 'center',
							'justify-end': _vm.alignment == 'right',
						} ],"prevPriceClass":[
						_vm.$bem('__prev-price'),
						'grey--text ml-2',
						_vm.timerProduct ? 'font-1' : 'font-2' ],"priceClass":[_vm.$bem('__price'), _vm.timerProduct ? 'font-6' : 'font-7', 'price line-clamp-1'],"discountClass":[_vm.$bem('__discount'), 'discount pa-2']}}),_c('hook',{attrs:{"zone":"attrs&financingTags"}},[_c('ProductCard-FinancingTag',{class:_vm.timerProduct ? 'font-0' : 'font-1'})],1),_c('hook',{attrs:{"zone":"textTags"}},[_c('ProductCard-TextTag',{class:_vm.timerProduct ? 'font-0' : 'font-1'})],1),_c('ProductCard-Tags',{class:_vm.timerProduct ? 'font-0' : 'font-1'}),_c('ProductCardRating',{attrs:{"product":_vm.product}}),_c('ProductCard-AttrsTags'),_c('ProductCard-Actions'),(_vm.timerProduct)?_c('hook',{attrs:{"zone":"offerTimerProduct"}},[(_vm.limitedTimeOffer)?_c('div',{class:[_vm.$bem('__offerTimerProduct'), 'base pt-1 mt-3']},[_c('LimitedTimeOfferCountdown',{class:{ 'mt-3': !_vm.hasAction },attrs:{"limitedTimeOffer":_vm.limitedTimeOffer,"timerCard":""}}),_c('div',{staticClass:"mt-2 pb-2 text-center"},[_c('p',{class:[
									_vm.$bem('__timer-date'),
									'text-uppercase line-clamp-3 mb-0 py-1 px-2 rounded font-0' ]},[_vm._v(" "+_vm._s(_vm.limitedTimeOfferDate)+" ")])])],1):_vm._e()]):_vm._e(),_c('hook',{class:{ 'no-stock': _vm.outOfStock },attrs:{"zone":"wishlist-toggler"}},[_c('ProductWishlistToggler',{class:_vm.$bem('__wishlist-toggler'),staticStyle:{"z-index":"1"},attrs:{"productId":_vm.product.id}})],1)],1)])],1)],1):_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onLinkBlurMobile),expression:"onLinkBlurMobile"}],class:{ 'product-card-design8-with-timer-horizontal-wrapper-forBtn': _vm.hasHoverAction }},[_c('v-card',{staticClass:"d-flex overflow-hidden base product-card pt-2 flex-column",class:[
			_vm.$bem('product-card-design8-with-timer-horizontal'),
			{
				'product-card-design8-with-timer-horizontal__noTimer': !_vm.timerProduct && _vm.$b.t,
				'card-border': _vm.cardBorder,
			} ],attrs:{"flat":"","ripple":false},on:{"click":_vm.cardClick}},[_c('div',{staticClass:"d-flex overflow-hidden py-2"},[_c('div',{class:[
					_vm.$bem('__image-cont', 'col col-5 pa-0 d-flex flex-column justify-center'),
					{ 'no-stock': _vm.outOfStock } ]},[_c('hook',{attrs:{"zone":"imageHorizontal"}},[_c('Media',{class:_vm.$bem('__main-image', 'px-1'),attrs:{"src":_vm.mainImage,"width":_vm.$b.m ? '100%' : '200px',"maxHeight":_vm.$b.t ? '200px' : '',"imgClass":"product-card-design8-with-timer-horizontal__image--smallWidth"}})],1),_c('hook',{attrs:{"zone":"event-iconHorizontal"}},[_c('CollectionEventIcon',{class:_vm.$bem('__event-icon'),attrs:{"product":_vm.product}})],1)],1),_c('div',{staticClass:"col col-7 py-0 d-flex flex-column justify-space-between text-start",class:[
					_vm.$bem('__data-cont'),
					{ 'no-stock': _vm.outOfStock, 'my-5': !_vm.hasAction && !_vm.timerProduct },
					_vm.$b.t ? 'pl-3' : 'pl-1' ],staticStyle:{"gap":"6px"}},[_c('hook',{attrs:{"zone":"brand"}},[_c('ProductCardText',{attrs:{"textClass":[_vm.$bem('__brand'), 'line-clamp-1', _vm.timerProduct ? 'font-0' : 'font-1'],"conditional":_vm.brand,"text":_vm.brand}})],1),_c('router-link',{attrs:{"to":_vm.productRoute,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
				var navigate = ref.navigate;
				var href = ref.href;
return [_c('a',{staticClass:"card-anchor header",attrs:{"href":href},on:{"click":function($event){return _vm.onLinkClickMobile(navigate, $event)}}},[_c('hook',{attrs:{"zone":"nameHorizontal"}},[_c('ProductCardText',{attrs:{"textClass":[_vm.$bem('__name'), 'line-clamp-2 header', _vm.$b.m ? 'font-3' : 'font-4'],"text":_vm.product.name}})],1)],1)]}}])}),_c('ProductCard-VariantSelector'),_c('ProductCardPrice',{attrs:{"product":_vm.product,"currency":_vm.currency,"priceAmount":_vm.price,"prevPriceAmount":_vm.prevPrice,"discountPct":_vm.discountPct,"containerClass":"d-flex align-center line-clamp-1","prevPriceClass":['grey--text ml-2', _vm.$bem('__prev-price'), _vm.$b.m ? 'font-1' : 'font-2'],"priceClass":[_vm.$bem('__price'), 'price line-clamp-1', _vm.$b.m ? 'font-5' : 'font-6'],"discountClass":[_vm.$bem('__discount'), 'discount pa-2'],"horizontalCard":""}}),_c('hook',{attrs:{"zone":"attrs&financingTagsHorizontal"}},[_c('ProductCard-FinancingTag')],1),_c('hook',{attrs:{"zone":"textTagsHorizontal"}},[_c('ProductCard-TextTag')],1),_c('ProductCard-Tags')],1)]),_c('ProductCardRating',{attrs:{"product":_vm.product}}),_c('ProductCard-Actions'),(_vm.timerProduct)?_c('hook',{class:{ 'no-stock': _vm.outOfStock },attrs:{"zone":"offerHorizontalTimerProduct"}},[(_vm.limitedTimeOffer)?_c('div',{class:[_vm.$bem('__offerTimerProduct'), 'base pt-1', { 'mt-4': _vm.$b.t }]},[_c('LimitedTimeOfferCountdown',{staticClass:"mt-2",attrs:{"limitedTimeOffer":_vm.limitedTimeOffer,"timerCard":""}}),_c('div',{staticClass:"mt-2 pb-2 text-center"},[_c('p',{staticClass:"text-uppercase line-clamp-3 mb-0 pt-1 pb-2 px-2 rounded font-0",class:_vm.$bem('__timer-date')},[_vm._v(" "+_vm._s(_vm.limitedTimeOfferDate)+" ")])])],1):_vm._e()]):_vm._e(),_c('hook',{attrs:{"zone":"wishlist-togglerHorizontal"}},[_c('ProductWishlistToggler',{class:[_vm.$bem('__wishlist-toggler'), { 'no-stock': _vm.outOfStock }],staticStyle:{"z-index":"1"},attrs:{"productId":_vm.product.id,"height":30,"width":30}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }